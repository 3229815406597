
// Components
import VIcon from '~/components/ui/icon/VIcon';
import VImage from '@@/common/components/ui/images/VImage.vue';
import { mapState } from 'vuex';
import VDropdown from 'portal/components/ui/dropdown/VDropdown.vue';

export default {
    name: 'VProfilePicture',
    components: {
        VDropdown,
        VImage,
        VIcon,
    },

    props: {
        image: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'user',
            validator: v => [
                'image',
                'user',
                'building',
                'building2',
                'number',
                'file',
            ].includes(v),
        },

        size: {
            type: String,
            default: '56',
            validator: v => ['custom', '80', '64', '56', '48', '40', '36', '32', '24'].includes(v),
        },

        imgSizes: {
            type: String,
            default: 'mobile:160px tablet:160px laptop:160px desktop:160px',
        },

        number: {
            type: [String, Number],
            default: null,
        },

        alt: {
            type: String,
            default: 'Изображение',
        },

        /**
         * для type="image"
         */
        contain: {
            type: Boolean,
            default: true,
        },

        round: {
            type: Boolean,
            default: false,
        },

        firstName: {
            type: String,
            default: '',
        },

        lastName: {
            type: String,
            default: '',
        },

        company: {
            type: Boolean,
            default: false,
        },

        color: {
            type: String,
            default: 'primary',
            validator: v => ['primary', 'secondary'].includes(v),
        },

        changePhoto: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            photo: {
                src: null,
                type: null,
            },

            isOpen: false,
        };
    },

    computed: {
        ...mapState({
            user: state => state.user?.user,
            previewUserAvatar: state => state.user?.previewUserAvatar,
            previewCompanyLogo: state => state.user?.previewCompanyLogo,
        }),

        classList() {
            return [
                this.$style[`_${this.size}`],
                this.$style[`_${this.type}`],
                {
                    [this.$style._round]: this.round,
                    [this.$style._initials]: this.initials,
                    [this.$style._company]: this.company,
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style._isImage]: this.image || this.previewImage,
                },
            ];
        },

        isIcon() {
            return ['user', 'building', 'building2', 'file'].includes(this.type);
        },

        initials() {
            return `${this.firstName ? this.firstName[0] : ''}`;
        },

        previewImage() {
            return this.user.is_company && this.previewCompanyLogo ? this.previewCompanyLogo : this.previewUserAvatar ? this.previewUserAvatar : null;
        },

        showAddPhoto() {
            return this.changePhoto && !this.image && !this.previewImage;
        },

        showChangePhoto() {
            return this.changePhoto && (this.image || this.previewImage);
        },
    },

    methods: {
        handleAddImage(event) {
            const file = event.target.files[0];
            if (file) {
                this.photo.src = URL.createObjectURL(file);
                this.photo.type = file.type;
            } else {
                // Если файл не выбран, очищаем данные изображения
                this.photo.src = null;
                this.photo.type = null;
            }
        },

        handleClick(event) {
            if (this.$deviceIs.pc) {
                return false;
            }
            event.preventDefault();

            const SelectInputType = () => import('portal/components/modals/cropper/SelectInputType.vue');
            this.$modal.open(SelectInputType, { showDeleteButton: Boolean(this.image) }, () => {
                this.$modal.event.$off('crop');
                this.$modal.event.$off('delete');
            });
            this.$modal.event.$on('crop', result => {
                this.onCropImage(result);
            });
        },

        onChange(event) {
            this.handleAddImage(event);
            this.openModal();
        },

        openModal() {
            const cropModal = () => import('portal/components/modals/cropper/CropModal.vue');
            const attr = {
                stencilProps: {
                    movable: false,
                    resizable: false,
                    handlers: {},
                    lines: {},
                },

                zoomButtons: true,
                image: this.photo,
                stencilComponent: 'circle-stencil',
                imageRestriction: 'stencil',
            };
            this.$modal.open(cropModal, attr, () => {
                this.$modal.event.$off('oncrop');
            });
            this.$modal.event.$on('oncrop', result => {
                this.onCropImage(result);
            });
        },

        onCropImage(val) {
            this.$emit('crop', val.base64);
            const VToast = () => import('portal/components/ui/toast/VToast.vue');
            this.$toast.open(VToast, { message: 'Фото профиля успешно обновлено' });
        },

        handleDelete() {
            this.$emit('delete');
        },

        handleDropdownClick(handler, closeHandler) {
            closeHandler();
            handler();
        },
    },
};
